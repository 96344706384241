import React from 'react';
import Icon from '@material-ui/core/Icon';
import APICategory from './components/APICategory.js'
import ExpansiveContainer from './components/ExpansiveContainer'
import './styles/guide.css';


const ExpansiveChild = ({ question, height }) => (
  <div className="question-container">
    <p>{question.question}</p>
    <div className="answer-container" style={{opacity: height === "auto"? 1 : 0, transition: "500ms" }}>
      {question.answer.map((text, i) =>
        <p key={`text-${i}`}>{text}</p>
      )}
    </div>
  </div>
);


class Guide extends React.Component{

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render(){
    const { data } = this.props
    return(
      <div className="guide-main">
        <div
          className="banner"
          style={{flexDirection: "column", alignItems: "flex-start", padding: "0 120px", justifyContent: "center", backgroundColor: "#F9FAFB"}}>
          <img src="/images/banner.svg" alt="banner"/>
          <p style={{padding: 0, margin: 0, color: "var(--main-color)"}}>Developer's Guide</p>
        </div>
        {data.groups.map((group, index) =>
          <div className="group-container" key={`group-${index}`}>
            <p>{group.title}</p>
            {group.questions.map((question, idx) =>
              <ExpansiveContainer
                key={`question-${idx}`}
                containerIndex={idx}
                >
                <ExpansiveChild question={question}/>
              </ExpansiveContainer>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Guide;
