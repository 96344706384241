import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

const HomeCarousel = (props)=>{
    return(
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={6000}
          transitionTime={700}
          >
          {props.data.map((slide, i) =>
            <div className="carousel-slide" key={`${i}`}>
              <img src={`${slide.image}`} alt={`${slide.image}`}/>
              <div className="carousel-txt">
                <div>
                  {slide.texts.map((t,i) =>
                    <p key={`${i}`}>{t}</p>
                  )}
                </div>
                {'buttonText' in slide && 'buttonLink' in slide && slide.buttonText && slide.buttonLink? <Link to={slide.buttonLink}><button>{slide.buttonText}</button></Link> : ''}
              </div>
            </div>
          )}
        </Carousel>
    )
  }

export default HomeCarousel
