import React from 'react';
import ImageBox from './components/ImageBox.js'
import Social from './components/Social.js'
import './styles/about.css';


class About extends React.Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }


  render(){
    const { data } = this.props

    return(
      <div>
        <div className="banner">
          <img src="/images/banner.svg" alt="banner"/>
          <p>{data.mainTitle}</p>
        </div>
        {data.boxes.map((b,i) =>
          <div className={(i % 2 === 0) || (i === 0)? "about-one" : "about-two"}>
            <ImageBox
              data={b}
              imageSide={(i % 2 === 0) || (i === 0)? "right" : "left"}
              />
          </div>
        )}
        <Social/>
      </div>
    )
  }
}

export default About;
