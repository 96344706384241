let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    needs: [
      {
        image: "/images/useCases/chatbot.png",
        title: "Enhanced Mobile Wallet",
        description: "As a small institution, we do not have the capacity to integrate our Enterprise Resource Planning systems with SWIFT which results in a lot of manual operations to manage incoming and outgoing money flows",
        category: "Payments",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/account-balance.png",
        title: "Automated Payments Engine",
        description: "As a small institution, we do not have the capacity to integrate our Enterprise Resource Planning systems with SWIFT which results in a lot of manual operations to manage incoming and outgoing money flows",
        category: "Payments",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/onboarding.png",
        title: "Fraudulent Behavior Detection",
        description: "Help us create a solution that optimizes and monitors individual transactions and identifies misconduct during the onboarding and service of customers.",
        category: "Regtech",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/transfers.png",
        title: "ID Documentation Validation",
        description: "Help us create a solution that allows easy, fast, and online validation of documentation for new account creation. It should provide access to a central database through a secure and highly-encrypted connection.",
        category: "Category",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
    ]
  },
  es: {
    needs: [
      {
        image: "/images/useCases/chatbot.png",
        title: "Enhanced Mobile Wallet",
        description: "As a small institution, we do not have the capacity to integrate our Enterprise Resource Planning systems with SWIFT which results in a lot of manual operations to manage incoming and outgoing money flows",
        category: "Payments",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/account-balance.png",
        title: "Automated Payments Engine",
        description: "As a small institution, we do not have the capacity to integrate our Enterprise Resource Planning systems with SWIFT which results in a lot of manual operations to manage incoming and outgoing money flows",
        category: "Payments",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/onboarding.png",
        title: "Fraudulent Behavior Detection",
        description: "Help us create a solution that optimizes and monitors individual transactions and identifies misconduct during the onboarding and service of customers.",
        category: "Regtech",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
      {
        image: "/images/useCases/transfers.png",
        title: "ID Documentation Validation",
        description: "Help us create a solution that allows easy, fast, and online validation of documentation for new account creation. It should provide access to a central database through a secure and highly-encrypted connection.",
        category: "Category",
        solutions: "3",
        posted: "26/03/2021",
        deadline: "28/03/2021"
      },
    ]
  },
}
