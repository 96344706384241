import React from 'react';

const Social = (props)=>{
    return(
      <div className="social-container">
        {/*<img src="/icons/ico-linkedin.svg" alt="linkedin-icon"/>*/}
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/icons/ico-instagram.svg" alt="instagram-icon"/></a>
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/icons/ico-twitter.svg" alt="twitter-icon"/></a>
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/icons/ico-facebook.svg" alt="facebook-icon"/></a>
      </div>
    )
  }

export default Social
