let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    groups: [
      {
        title: "About Us",
        questions: [
          {
            question: "Who can Participate?",
            answer: [
              "According to the research firm Frost & Sullivan, the estimated size of the North American used test and measurement equipment market was $446.4 million in 2004 and is estimated to grow to $654.5 million by 2011. For over 50 years, companies and governments have procured used test and measurement instruments in order to realize a number of benefits including the need to:",
              "– reduce equipment acquisition costs",
              "– replace discontinued testing instruments"
            ]
          },
          {
            question: "What are the Open Innovation Programs?",
            answer: [
              "Content"
            ]
          },
        ]
      },
      {
        title: "APIs",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Testing",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Support",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
    ]
  },
  es: {
    groups: [
      {
        title: "About Us",
        questions: [
          {
            question: "Who can Participate?",
            answer: [
              "According to the research firm Frost & Sullivan, the estimated size of the North American used test and measurement equipment market was $446.4 million in 2004 and is estimated to grow to $654.5 million by 2011. For over 50 years, companies and governments have procured used test and measurement instruments in order to realize a number of benefits including the need to:",
              "– reduce equipment acquisition costs",
              "– replace discontinued testing instruments"
            ]
          },
          {
            question: "What are the Open Innovation Programs?",
            answer: [
              "Content"
            ]
          },
        ]
      },
      {
        title: "APIs",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Testing",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Support",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
    ]
  },
}
