import React from 'react';
import axios from 'axios'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Home.js'
import About from './About.js'
import UseCases from './UseCases.js'
import Catalog from './Catalog.js'
import Guide from './Guide.js'
import Needs from './Needs.js'
import InnovationPrograms from './InnovationPrograms.js'
import Navbar from './components/Navbar.js'
import CompressedNavbar from './components/CompressedNavbar.js'
import homeData from './content/home.js'
import aboutData from './content/about.js'
import useCasesData from './content/useCases.js'
import navbarData from './content/navbar.js'
import guideData from './content/guide.js'
import innovationData from './content/innovation.js'
import needsData from './content/needs.js'

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      language: 'en',
      apiInformation: []
    };
    this.handleLanguageSwitch = this.handleLanguageSwitch.bind(this)
  }

  componentDidMount(){
    if(localStorage.getItem('4lng')){
      this.setState({language: localStorage.getItem('4lng')},() => {
        this.getAPIsInformation('escalabank', this.state.language)
      })
    }else{
      this.getAPIsInformation('escalabank', this.state.language)
    }
  }

  async getAPIsInformation(id, lang=''){
    let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
    let paramsString = lang && lang !== 'en'? `summary?entity=${id}&lang=${lang}` : `summary?entity=${id}`
    await axios.get(`${marketplace}/api/apis/${paramsString}`).then(res => {
      this.setState({apiInformation: res.data})
    });
  }

  handleLanguageSwitch(lang){
    this.setState({language: lang})
    localStorage.setItem('4lng', lang);
    window.location.reload()
    this.getAPIsInformation('escalabank', lang)
  }

  render(){
    const { language, apiInformation } = this.state

    return(
      <div>
        <Router>
          <Navbar
            data={navbarData[`${language}`]}
            language={language}
            handleLanguageSwitch={this.handleLanguageSwitch}
            />
          <CompressedNavbar
            data={navbarData[`${language}`]}
            language={language}
            handleLanguageSwitch={this.handleLanguageSwitch}
            />
          <Route
            exact
            path='/'
            render={(props) => <Home {...props} data={homeData[`${language}`]}/>}
          />
          <Route
            exact
            path='/about'
            render={(props) => <About {...props} data={aboutData[`${language}`]}/>}
          />
          <Route
            exact
            path='/use-cases/:useCase'
            render={(props) => <UseCases {...props} data={useCasesData[`${language}`]} language={language}/>}
          />
          <Route
            exact
            path='/catalog'
            render={(props) => <Catalog {...props}
                                  data={apiInformation}
                                  language={language}
            />}
          />
          <Route
            exact
            path='/innovation-programs'
            render={(props) => <InnovationPrograms {...props}
                                  data={innovationData[`${language}`]}
                                  language={language}
            />}
          />
          <Route
            exact
            path='/guide'
            render={(props) => <Guide {...props} data={guideData[`${language}`]}/>}
          />
          <Route
            exact
            path='/needs'
            render={(props) => <Needs {...props} data={needsData[`${language}`]}/>}
          />
        </Router>
      </div>
    )
  }
}

export default App
