import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Carousel from "react-multi-carousel";

const styles = theme => ({
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1700 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1699, min: 1300 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1299, min: 766 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 765, min: 0 },
    items: 1
  }
};


class Slider extends Component {
	render() {
    const { classes, children } = this.props;
		return (
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={false}
            autoPlay={false}
            keyBoardControl={false}
            transitionDuration={500}
            containerClass="slider-container"
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
          	{children}
          </Carousel>
		);
	}
}

export default withStyles(styles)(Slider);
