let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    programs: [
      {
        dueDate: "March 26-28, 2021",
        title: "Customer Engagement Challenge",
        description: "Escala Bank launches the Customer Engagement Challenge where Fintech companies and third party developers are invited to embrace crowdsourcing and open innovation collaboration from experimenting, testing, and running POCs, to providing fully developed solutions in record time. During the program, participants will have access to the platform.",
        tabs: [
          "Customer engagement is a key priority to our institution. We are looking for new ideas to strengthen the menu of options that we offer clients and prospects. As we begin to scale, manually managing reward programs to a large client base is becoming laborious and costly. Your solution can be focused on any type of reward programs. We are looking for personalized and engaging proposals to maximize client retention and consumption of our products and services.",
          "Requirements",
          "Benefits",
          "Key dates"
        ],
        needs: [
          {
            image: "/images/useCases/loyalty.png",
            title: "Aggregated Loyalty Rewards",
            description: "Help consumers manage a number of reward programs in a single place by creating a solution that merges loyalty reward from multiple merchants and enables the cross redemption of reward points from different merchants.",
            category: "Rewards",
            solutions: "3",
            posted: "26/03/2021",
            deadline: "Deadline: 28/03/2021"
          },
          {
            image: "/images/useCases/payment.png",
            title: "Customized Loyalty Rewards",
            description: "Help consumers manage a number of reward programs in a single place by creating a solution that merges loyalty reward from multiple merchants and enables the cross redemption of reward points from different merchants.",
            category: "Rewards",
            solutions: "3",
            posted: "26/03/2021",
            deadline: "Deadline: 28/03/2021"
          },
        ]
      }
    ]
  },
  es: {
    programs: [
      {
        dueDate: "March 26-28, 2021",
        title: "Customer Engagement Challenge",
        description: "Escala Bank launches the Customer Engagement Challenge where Fintech companies and third party developers are invited to embrace crowdsourcing and open innovation collaboration from experimenting, testing, and running POCs, to providing fully developed solutions in record time. During the program, participants will have access to the platform.",
        tabs: [
          "Customer engagement is a key priority to our institution. We are looking for new ideas to strengthen the menu of options that we offer clients and prospects. As we begin to scale, manually managing reward programs to a large client base is becoming laborious and costly. Your solution can be focused on any type of reward programs. We are looking for personalized and engaging proposals to maximize client retention and consumption of our products and services.",
          "Requirements",
          "Benefits",
          "Key dates"
        ],
        needs: [
          {
            image: "/images/useCases/loyalty.png",
            title: "Aggregated Loyalty Rewards",
            description: "Help consumers manage a number of reward programs in a single place by creating a solution that merges loyalty reward from multiple merchants and enables the cross redemption of reward points from different merchants.",
            category: "Rewards",
            solutions: "3",
            posted: "26/03/2021",
            deadline: "Deadline: 28/03/2021"
          },
          {
            image: "/images/useCases/payment.png",
            title: "Customized Loyalty Rewards",
            description: "Help consumers manage a number of reward programs in a single place by creating a solution that merges loyalty reward from multiple merchants and enables the cross redemption of reward points from different merchants.",
            category: "Rewards",
            solutions: "3",
            posted: "26/03/2021",
            deadline: "Deadline: 28/03/2021"
          },
        ]
      }
    ]
  },
}
