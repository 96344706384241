import React from 'react';
import API from './API.js'
import Slider from './Slider.js'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1440 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1440, min: 1390 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1390, min: 748 },
    items: 3
  },
  tablet2: {
    breakpoint: { max: 1096, min: 748 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 748, min: 0 },
    items: 1
  }
};

const APICategory = (props)=>{
  const { data, flipCard, cardFront, category, selectedAPIs } = props
  return(
    <div className="category-container">
      <p className="category-title">{category}</p>
      <Slider>
        {
          data.apiList.map((a, i) =>
          <div className="category-data" onClick={(e) => flipCard(`${category}-${i}`)} key={`${category}-${i}`}>
            <API
              data={a}
              cardFront={cardFront}
              clickedAPI={selectedAPIs.includes(`${category}-${i}`)? true : false}
              />
          </div>
        )}
      </Slider>

    </div>
  )
}

export default APICategory


// {
//   data.apiList.map((a, i) =>
//   <div className="category-data-mobile" onClick={(e) => flipCard(`${category}-${i}`)} key={`${category}-${i}`}>
//     <API
//       data={a}
//       cardFront={cardFront}
//       clickedAPI={selectedAPIs.includes(`${category}-${i}`)? true : false}
//       />
//   </div>
// )}
