import React from 'react';
import { Link } from "react-router-dom";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Social from './components/Social.js'
import API from './components/API.js'

import './styles/useCases.css';


const apisList = [
  {
    apiCertifications: ["PSD2", "OAuth"],
    apiCountries: ["Bolivia, Plurinational State of", "Tanzania, United Republic of", "Dominican Republic"],
    apiDescription: "Generate one-time Token as part of MFA (multi-factor authentication) mechanism.",
    apiEntityName: "4wrd Default",
    apiIcon: "ico-token-post@2x.png-1588283483699",
    apiId: "5e95210fd4f62ed2dda80a5a",
    apiImage: "Token.png-1587757055682",
    apiName: "Generate Token",
    apiStage: "sandbox",
    apiVisibility: "registered",
  },
  {
    apiCertifications: ["OAuth", "PSD2"],
    apiCountries: ["Bolivia, Plurinational State of", "Tanzania, United Republic of", "Mexico", "Honduras", "Dominican Republic"],
    apiDescription: "Get list of accounts in a single institution associated to a client .",
    apiEntityName: "4wrd Default",
    apiIcon: "ico-accounts-get@2x.png-1587757344568",
    apiId: "5e952425d4f62ed2ddb028f5",
    apiImage: "Accounts.png-1587659587258",
    apiName: "Get Accounts",
    apiStage: "sandbox",
    apiVisibility: "registered"
  },
  {
    apiCertifications:["OAuth", "PSD2"],
    apiCountries: ["Bolivia, Plurinational State of", "Tanzania, United Republic of", "Panama", "Honduras", "Mexico", "Dominican Republic"],
    apiDescription: "Retrieve location of ATMs, Branches, Kiosks, and other facilities associated to a specific institution.",
    apiEntityName: "4wrd Default",
    apiIcon: "ico-key-get@2x.png-1587926159580",
    apiId: "5ea30a2c374a827d99b1be6f",
    apiImage: "Logistics.png-1587926077156",
    apiName: "Get Location by Type",
    apiStage: "sandbox",
    apiVisibility: "public",
  }
]


class UseCases extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedUseCase: '',
      useCasesArray: [],
      language: '',
      selectedAPIs: []
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.createUseCaseArray()
  }

  createUseCaseArray(){
    let uCArray = []
    for(let useCase in this.props.data.useCases){
      uCArray.push({
        icon: useCase,
        title: this.props.data.useCases[useCase].title
      })
    }
    this.setState({useCasesArray:uCArray, language: this.props.language})
  }

  flipCard(selected){
    let apiFronts = this.state.selectedAPIs.map(a => a)
    apiFronts.includes(selected)?
      apiFronts.splice(apiFronts.indexOf(selected), 1):
      apiFronts.push(selected)
    this.setState({cardFront: !this.state.cardFront, selectedAPIs: apiFronts})
  }


  render(){
    const { data, language } = this.props
    const { selectedUseCase, selectedAPIs } = this.state
    const pathnameArray = window.location.pathname.split('/');
    const pathname = pathnameArray[pathnameArray.length - 1]

    let urlString = window.location.href.split('=')
    let selectedUC = ''
    if(urlString.length > 1){
      selectedUC = urlString[1]
    }

    if(selectedUC !== selectedUseCase){
      this.setState({selectedUseCase: selectedUC})
    }

    if(language !== this.state.language){
      this.createUseCaseArray()
    }

    let currentCase = data.useCases[pathname];

    return(
      <div style={{width:'100vw', height: '100vh'}}>
        <div className="banner">
          <img src="/images/banner.svg" alt="banner"/>
          {currentCase?
            <p style={{textTransform: 'uppercase'}}>{data.useCases[pathname].title}</p>
            :
            <div className="use-case-title">
              <p style={{whiteSpace: 'break-spaces'}}>{data.useCases[selectedUseCase].title}</p>
            </div>
          }
        </div>
        {currentCase?
          <div className="use-case-container">
            <div className="use-case-problems">
              <div>
                <p className="use-case-problems-title">Problem</p>
                <p>{currentCase.problem}</p>
              </div>
              <div>
                <p className="use-case-problems-title">Solution</p>
                <p>{currentCase.solution}</p>
              </div>
            </div>
            <img alt="use-case-img" src={currentCase.image}/>
          </div>
          :
          ''
        }
        {currentCase?
          <div className="use-case-apis">
            <p className="use-case-apis-title">APIs Needed</p>
            <div className="category-container" style={{flexDirection: "row"}}>
              {apisList.map((api, idx) =>
                <div
                  className="category-data"
                  key={`api-${idx}`}
                  onClick={(e) => this.flipCard(`api-${idx}`)}
                  style={{background: "none"}}
                  >
                  <API
                    data={api}
                    clickedAPI={selectedAPIs.includes(`api-${idx}`)? true : false}
                    />
                </div>
              )}
            </div>
          </div>
          :
          ''
        }
        <Social/>
      </div>
    )
  }
}

export default UseCases;
