import React from 'react';
import Icon from '@material-ui/core/Icon';
let source  = process.env.REACT_APP_SOURCE || ""


const Need = (props)=>{
  const { data, clickedNeed } = props
  let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"


  return(
    <div className="need-container" style={clickedNeed? {transform: 'rotateY(180deg)'} : {}}>
      <div  className="card-front">
        <img
          className="api-icon"
          src={data && data.image? data.image : ''}
          alt="need-img"/>
        <div className="api-front-data">
          <div className="api-title">
            <p>{'title' in data? data.title : ''}</p>
            <p>{'description' in data? data.description : ''}</p>
          </div>
          <div className="tags">
            {'category' in data && data.category?
              <div>
                <p>{data.category}</p>
              </div>
               : ''
             }
          </div>
        </div>
      </div>
      <div className="card-back">
        <p style={{marginBottom: 0}}>{'title' in data? data.title : ''}</p>
        <div className="need-back-data">
          <p>Challenge: {props.challengeName? props.challengeName : "NA"}</p>
          <p>Category: {data.category}</p>
          <p>Proposed solutions: {data.solutions}</p>
          <p>Posted: {data.posted}</p>
          <p>Deadline: {data.deadline}</p>
        </div>
        <a href={`${marketplace}/escalabank/register?source=${source}`} target="_blank" rel="noopener noreferrer" className="apply">
          <p className="title">Apply to Solve Need</p>
          <Icon style={{color: "var(--main-color)"}}>arrow_right_alt</Icon>
        </a>
     </div>
    </div>
  )
}

export default Need
