
export default {
  en: {
    welcome: {
      image: '/images/welcome-pic.png',
      title: 'Welcome to Escala Bank for Developers',
      texts: ['Gain access to the tools and information you need to consume APIs. Explore the ways you can build with Escala.'],
      subtitle: 'Unlock your potential through our Innovation Programs',
      button: {
        text: 'Innovation Programs',
        link: '/innovation-programs'
      }
    },
    cards: [
      {
        title: 'Solve Needs',
        text: 'Bring solutions to our needs',
        link: '/needs',
        icon: 'account_balance'
      },
      {
        title: 'Browse APIs',
        text: 'Navigate through our API Catalogue',
        link: '/catalog',
        icon: 'api'
      },
      {
        title: 'Start Building',
        text: "Let's get started!",
        link: '/guide',
        icon: 'code'
      },
    ],
    slides: [
      {
        title: '',
        image: '/images/home-carousel-1.png',
        texts: [
          'Open Banking emerges in the financial industry, as a new model of collaboration with third party providers. Through APIs, banks, fintechs, and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience.'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'We are excited to introduce our 2019 Top 25 Most Innovative Bankers in Latin America & the Caribbean.',
          'This year’s list of the bankers – and banks! - showcases a variety of ways that institutions are leveraging technology and innovation to transform for the digital age.'
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect your innovative products and services with our customers, we make it simple and easy! Scale globally and achieve time to market by using our standardized APIs, we include unlimited testing in our Sandbox! It is easier than you think!'
        ],
        image: '/images/bottom-box-img.png',
        buttonLink: '/build',
        buttonText: 'Start building'
      }
  },
  es: {
    welcome: {
      image: '/images/welcome-pic.png',
      title: 'Welcome to Escala Bank for Developers',
      texts: ['Gain access to the tools and information you need to consume APIs. Explore the ways you can build with Escala.'],
      subtitle: 'Unlock your potential through our Innovation Programs',
      button: 'Innovation Programs'
    },
    cards: [
      {
        title: 'Solve Needs',
        text: 'Bring solutions to our needs',
        link: '#',
        icon: 'account_balance'
      },
      {
        title: 'Browse APIs',
        text: 'Navigate through our API Catalogue',
        link: '#',
        icon: 'api'
      },
      {
        title: 'Start Building',
        text: "Let's get started!",
        link: '#',
        icon: 'code'
      },
    ],
    slides: [
      {
        title: '',
        image: '/images/home-carousel-1.png',
        texts: [
          'Open Banking emerges in the financial industry, as a new model of collaboration with third party providers. Through APIs, banks, fintechs, and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience.'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'We are excited to introduce our 2019 Top 25 Most Innovative Bankers in Latin America & the Caribbean.',
          'This year’s list of the bankers – and banks! - showcases a variety of ways that institutions are leveraging technology and innovation to transform for the digital age.'
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect your innovative products and services with our customers, we make it simple and easy! Scale globally and achieve time to market by using our standardized APIs, we include unlimited testing in our Sandbox! It is easier than you think!'
        ],
        image: '/images/bottom-box-img.png',
        buttonLink: '/build',
        buttonText: 'Start building'
      }
  },
}
