import React from 'react';
import { Link } from "react-router-dom";

const ImageBox = (props)=>{
  const { data, imageSide } = props
    return(
      <div className="image-box">
        {'image' in data && data.image && 'imageSide' in props && imageSide === 'left'? <img className="image-left" src={`${data.image}`} alt={`${data.image}`}/> : ''}
        <div className="image-box-txt">
          {'title' in data && data.title? <p className="image-box-title">{data.title}</p> : ''}
          {'texts' in data && data.texts.length? data.texts.map((t,i) => <p key={`${i}`}>{t}</p>) : ''}
          {'buttonText' in data && data.buttonText?
            data.buttonLink.includes('http')?
            <a href={data.buttonLink}><button>{data.buttonText}</button></a> 
            :
            <Link to={data.buttonLink}><button>{data.buttonText}</button></Link>
          : ''}
        </div>
        {'image' in data && data.image && 'imageSide' in props && imageSide === 'right'? <img className="image-right" src={`${data.image}`} alt={`${data.image}`}/> : ''}
      </div>
  )
}

export default ImageBox
