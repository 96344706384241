let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    useCases: {
      onboarding: {
        title: " Onboarding: new account for new client",
        image: "/images/useCases/onboarding.png",
        problem: "Your onboarding process requires your customers to open new accounts. You need this to be embedded in your process and give you the ability to control the customer journey and user experience.This also creates a faster and safer experience for your customer.",
        solution:"Our APIs allow your application to create accounts for your individual or business customers within your application and under your brand, with these accounts originated as actual bank accounts in the bank and updated in real time.",
        apisNeeded: [

        ]
      },
      openaccount: {
        title: "Open account for existing client",
        image: "/images/useCases/open-account.png",
        problem: "As your customer uses your application, they will need to open a bank account to access some additional or new functionalities. You need them to be able to do this without any friction and as part of their user experience within your application.",
        solution:"Our APIs allow your application to create accounts for your individual or business customers within your application and under your brand, with these accounts originated as actual bank accounts in the bank and updated in real time.",
        apisNeeded: [

        ]
      },
      loyalty: {
        title: "Loyalty/rewards program",
        image: "/images/useCases/loyalty.png",
        problem: "You want to reward, motivate and retain your customers. You want to be able to track their activities, offer them incentives and let them redeem these offers through their bank accounts.",
        solution:"Our APIs provide a frictionless way for customers to register for and earn instant incentives and rewards by connecting their accounts to your application, creating personalized experiences and building customer insights.",
        apisNeeded: [

        ]
      },
      withdraw: {
        title: "Withdraw and deposit cash",
        image: "/images/useCases/withdraw.png",
        problem: "Cash continues to be relevant or required for some types of transactions or may be a preferred payment method in some places where the other methods are less popular. You want to serve your customers by enabling cash-out/cash withdrawal functionality for your customers to serve them better.",
        solution:" Our APIs will allow your applications to be quickly incorporated into a network of already affiliated businesses which are enabled as points of sale to recharge balances and withdraw cash. You will thus be able to offer cash withdrawal and deposits through your app, serving this need for your end users.",
        apisNeeded: [

        ]
      },
      payment: {
        title: "Payment from account",
        image: "/images/useCases/payment.png",
        problem: "You want your customers to be able to make payments from their bank account through your app. Without this ability, customers will have to leave your app to make payments through other means, breaking their journey with your app and introducing friction into their experience.",
        solution:"Our APIs offer the necessary connections to integrate payments functionality to your application. By connecting to authentication and payment APIs, you get the pieces to offer your customers features for making payments to and through your app in a simple online process. ",
        apisNeeded: [

        ]
      },
      transfers: {
        title: "Transfers between accounts",
        image: "/images/useCases/transfers.png",
        problem: "You want your user to be able to move money via ACH between bank accounts in different financial institutions using your application. ",
        solution:"Using our APIs, you can connect to the central settlement systems through payment APIs and facilitate transfers via SWIFT using *the financial institution's* reconciliation account. Using your solution, users can send money, settle bills, pay friends, send a gift from their account directly to beneficiary bank accounts without having to visit any of the banks involved.",
        apisNeeded: [

        ]
      },
      attributes: {
        title: "Check account attributes",
        image: "/images/useCases/check-account.png",
        problem: "You want to enable your app to access bank account information that is a crucial part of the customer experience. You want to empower your clients with additional bank account information to help them confirm as they use your app. ",
        solution: "Our accounts API lets you access key account data such as account number,  account location, type of account, routing number and account branch. This helps your client to confirm account details and helps them to safely and properly transact with the required account details.",
        apisNeeded: [

        ]
      },
      accountbalance: {
        title: "Check account balance and movements",
        image: "/images/useCases/account-balance.png",
        problem: "You want to enable your app to access real time bank account information that is a crucial part of the customer experience. You want to empower your clients with information like account balances and activities to help them make decisions or confirmations as they use your app. ",
        solution:"Using our APIs, your application can access  account transaction data and account statuses instantly and in real time so that your clients are better informed for their decisions. ",
        apisNeeded: [

        ]
      },
      geolocation: {
        title: "Geolocation",
        image: "/images/useCases/geolocation.png",
        problem: "You want to give your users convenience by connecting them with information about the location of branches, ATMs or payment points in a map.",
        solution:"Our geolocation APIs enable users to locate all branches throughout the region. Using longitudinal and latitudinal data, users can get needed details like the location of a branch/ATM/payment point, the opening hours and the facilities available.",
        apisNeeded: [

        ]
      },
      chatbot: {
        title: "Chatbotd",
        image: "/images/useCases/chatbot.png",
        problem: "You want to give your users convenience by connecting them with information about the location of branches, ATMs or payment points in a map.",
        solution:"Our geolocation APIs enable users to locate all branches throughout the region. Using longitudinal and latitudinal data, users can get needed details like the location of a branch/ATM/payment point, the opening hours and the facilities available.",
        apisNeeded: [

        ]
      },

    }
  },
  es: {
    useCases: {
      onboarding: {
        title: " Onboarding: new account for new client",
        image: "/images/useCases/onboarding.png",
        problem: "Your onboarding process requires your customers to open new accounts. You need this to be embedded in your process and give you the ability to control the customer journey and user experience.This also creates a faster and safer experience for your customer.",
        solution:"Our APIs allow your application to create accounts for your individual or business customers within your application and under your brand, with these accounts originated as actual bank accounts in the bank and updated in real time.",
        apisNeeded: [

        ]
      },
      openaccount: {
        title: "Open account for existing client",
        image: "/images/useCases/open-account.png",
        problem: "As your customer uses your application, they will need to open a bank account to access some additional or new functionalities. You need them to be able to do this without any friction and as part of their user experience within your application.",
        solution:"Our APIs allow your application to create accounts for your individual or business customers within your application and under your brand, with these accounts originated as actual bank accounts in the bank and updated in real time.",
        apisNeeded: [

        ]
      },
      loyalty: {
        title: "Loyalty/rewards program",
        image: "/images/useCases/loyalty.png",
        problem: "You want to reward, motivate and retain your customers. You want to be able to track their activities, offer them incentives and let them redeem these offers through their bank accounts.",
        solution:"Our APIs provide a frictionless way for customers to register for and earn instant incentives and rewards by connecting their accounts to your application, creating personalized experiences and building customer insights.",
        apisNeeded: [

        ]
      },
      withdraw: {
        title: "Withdraw and deposit cash",
        image: "/images/useCases/withdraw.png",
        problem: "Cash continues to be relevant or required for some types of transactions or may be a preferred payment method in some places where the other methods are less popular. You want to serve your customers by enabling cash-out/cash withdrawal functionality for your customers to serve them better.",
        solution:" Our APIs will allow your applications to be quickly incorporated into a network of already affiliated businesses which are enabled as points of sale to recharge balances and withdraw cash. You will thus be able to offer cash withdrawal and deposits through your app, serving this need for your end users.",
        apisNeeded: [

        ]
      },
      payment: {
        title: "Payment from account",
        image: "/images/useCases/payment.png",
        problem: "You want your customers to be able to make payments from their bank account through your app. Without this ability, customers will have to leave your app to make payments through other means, breaking their journey with your app and introducing friction into their experience.",
        solution:"Our APIs offer the necessary connections to integrate payments functionality to your application. By connecting to authentication and payment APIs, you get the pieces to offer your customers features for making payments to and through your app in a simple online process. ",
        apisNeeded: [

        ]
      },
      transfers: {
        title: "Transfers between accounts",
        image: "/images/useCases/transfers.png",
        problem: "You want your user to be able to move money via ACH between bank accounts in different financial institutions using your application. ",
        solution:"Using our APIs, you can connect to the central settlement systems through payment APIs and facilitate transfers via SWIFT using *the financial institution's* reconciliation account. Using your solution, users can send money, settle bills, pay friends, send a gift from their account directly to beneficiary bank accounts without having to visit any of the banks involved.",
        apisNeeded: [

        ]
      },
      attributes: {
        title: "Check account attributes",
        image: "/images/useCases/check-account.png",
        problem: "You want to enable your app to access bank account information that is a crucial part of the customer experience. You want to empower your clients with additional bank account information to help them confirm as they use your app. ",
        solution: "Our accounts API lets you access key account data such as account number,  account location, type of account, routing number and account branch. This helps your client to confirm account details and helps them to safely and properly transact with the required account details.",
        apisNeeded: [

        ]
      },
      accountbalance: {
        title: "Check account balance and movements",
        image: "/images/useCases/account-balance.png",
        problem: "You want to enable your app to access real time bank account information that is a crucial part of the customer experience. You want to empower your clients with information like account balances and activities to help them make decisions or confirmations as they use your app. ",
        solution:"Using our APIs, your application can access  account transaction data and account statuses instantly and in real time so that your clients are better informed for their decisions. ",
        apisNeeded: [

        ]
      },
      geolocation: {
        title: "Geolocation",
        image: "/images/useCases/geolocation.png",
        problem: "You want to give your users convenience by connecting them with information about the location of branches, ATMs or payment points in a map.",
        solution:"Our geolocation APIs enable users to locate all branches throughout the region. Using longitudinal and latitudinal data, users can get needed details like the location of a branch/ATM/payment point, the opening hours and the facilities available.",
        apisNeeded: [

        ]
      },
      chatbot: {
        title: "Chatbotd",
        image: "/images/useCases/chatbot.png",
        problem: "You want to give your users convenience by connecting them with information about the location of branches, ATMs or payment points in a map.",
        solution:"Our geolocation APIs enable users to locate all branches throughout the region. Using longitudinal and latitudinal data, users can get needed details like the location of a branch/ATM/payment point, the opening hours and the facilities available.",
        apisNeeded: [

        ]
      },

    }
  },
}
