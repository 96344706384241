import React from 'react';
import Icon from '@material-ui/core/Icon';
import Need from './components/Need.js'
import './styles/catalog.css';


class Needs extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      language: '',
      selectedNeeds: []
    }
    this.flipCard = this.flipCard.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }


  flipCard(selected){
    let needFronts = this.state.selectedNeeds.slice()
    needFronts.includes(selected)?
      needFronts.splice(needFronts.indexOf(selected), 1):
      needFronts.push(selected)
    this.setState({selectedNeeds: needFronts})
  }


  render(){
    const { data } = this.props;
    const { selectedNeeds } = this.state;

    return(
      <div>
        <div className="banner" style={{flexDirection: "column", alignItems: "flex-start", padding: "0 120px", justifyContent: "center"}}>
          <img src="/banner.svg" alt="banner"/>
          <p style={{padding: 0, margin: 0}}>Build Solutions</p>
          <a href="/guide" style={{display: "flex", alignItems: "center"}}>
            <p style={{color: "white"}}>Don´t know how to start? Check out our Developer’s guide</p>
            <Icon style={{color: "white"}}>arrow_right_alt</Icon>
          </a>
        </div>
        <div className="category-container" style={{flexDirection: "row", padding: "90px"}}>
          {data.needs.map((need, idx) =>
            <div
              className="category-data"
              key={`need-${idx}`}
              onClick={(e) => this.flipCard(`need-${idx}`)}
              style={{background: "none"}}
              >
              <Need
                data={need}
                clickedNeed={selectedNeeds.includes(`need-${idx}`)? true : false}
                />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Needs;
